import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Image from "~components/image/image"
import Page from "~components/page/page"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"

/* Import Local Components */
import LegalHeader from "./components/legalHeader"

/* Import Local Style(s) */
import "./post.scss"

const Post = ({ location, data: { sanityLegalPage: legalPage, sanityWebsiteConfiguration: config } }) => {
  const { pageTitle, _rawBody } = legalPage
  const { legalOrder } = config

  // console.log(location)

  return (
    <Page title={pageTitle} location={location} className="template">
      <Helmet>
        <style>{`body { background-color: white; }`}</style>
      </Helmet>
      <LegalHeader order={legalOrder} title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="post legal-post">
        <PageArticleSection className="post font-size--small">
          <BlockContent blocks={_rawBody} />
        </PageArticleSection>
      </PageArticle>
    </Page>
  )
}

export default Post

export const query = graphql`
  query ($id: String!) {
    sanityLegalPage(id: { eq: $id }) {
      ...fieldsOnLegalPageDocument
    }
    sanityWebsiteConfiguration {
      ...fieldsOnWebsiteConfigurationDocument
    }
  }
`
